/* hide reCaptcha */
.grecaptcha-badge {
  display: none;
}




/* settings 
    roles management */

.roles-management-container {
  display: flex;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.sidebar {
  width: 250px;
  background-color: #F2F2F2;
  padding: 20px;
  box-sizing: border-box;
  height: 100vh;
}

.create-role-button {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.create-role-button:hover {
  background-color: #45a049;
}

.system-feature {
  padding: 10px 0;
  cursor: pointer;
}


.role-creation-panel {
  flex-grow: 1;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 60px;
  overflow-y: auto;
  max-height: calc(100vh - 40px);
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0px 4px 21px 0px rgba(20, 40, 48, 0.04);
}

.system-features .system-feature {
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #142830;
}

.system-features .system-feature.active {
  background-color: #DFE3E7;
}

/* settings 
        roles management 
          create Role*/

.create-new-role-title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-role-btn {
  background-color: #EC2842;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.divider {
  margin-top: 10px;
  margin-bottom: 20px;
}

.role-form .form-row {
  display: flex;
  justify-content: space-between;
}

.role-form .form-field {
  margin-bottom: 10px;
  flex: 1;
}

.role-form label {
  display: block;
  margin-bottom: 5px;
}

.role-form .form-row .form-field:first-child {
  margin-right: 20px;
}

.role-form input[type="text"],
.role-form input[type="color"],
.role-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.role-form textarea {
  height: 100px;
}

/* color input */
.color-picker {
  display: flex;
  align-items: center;
}

.color-picker input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
  padding-right: 45px;
  box-sizing: border-box;
}

.color-input-wrapper {
  position: relative;
  margin-left: -50px;
  /* Adjust position to the left */
  width: 40px;
}

.color-picker input[type="color"] {
  width: 35px;
  height: 35px;
  border: none;
  padding: 0;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.color-picker input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

/* table */
.permissions-table {
  width: 100%;
  border-collapse: collapse;
}

.permissions-table td {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

input[type="checkbox"] {
  margin-right: 5px;
  color: #EC2842;
  border-radius: 50%;
}

input[type="checkbox"]:checked+label {
  color: #FF0000 !important;
}

.column-header-cell {
  background-color: #f0f0f0;
}


:root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --transDur: 0.15s;
  /* font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320)); */
}


.notification {
  padding-bottom: 0.75em;
  position: fixed;
  top: 9pc;
  right: 1.5em;
  width: 18.75em;
  max-width: calc(100% - 3em);
  transition: transform 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.notification__box,
.notification__content,
.notification__btns {
  display: flex;
}

.notification__box,
.notification__content {
  align-items: center;
}

.notification__box {
  animation: flyIn 0.3s ease-out;
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.75em;
  box-shadow: 0 0.5em 1em hsla(var(--hue), 10%, 10%, 0.1);
  height: 3em;
  transition:
    background-color var(--transDur),
    color var(--transDur);
}

.notification--out .notification__box {
  animation: flyOut 0.3s ease-out forwards;
}

.notification__content {
  padding: 0.375em 1em;
  width: 100%;
  height: 100%;
}

.notification__icon {
  flex-shrink: 0;
  margin-right: 0.75em;
  width: 2em;
  height: 2em;
}

.notification__icon-svg {
  width: 100%;
  height: auto;
}

.notification__text {
  line-height: 1.333;
}

.notification__text-title {
  font-size: 0.75em;
  font-weight: bold;
}

.notification__text-context {
  font-size: 10px !important;
  opacity: 0.75;
}

.notification__btns {
  box-shadow: -1px 0 0 hsla(var(--hue), 10%, 10%, 0.15);
  flex-direction: column;
  flex-shrink: 0;
  min-width: 4em;
  height: 100%;
  transition: box-shadow var(--transDur);
}

.notification__btn {
  background-color: transparent;
  box-shadow: 0 0 0 hsla(var(--hue), 10%, 10%, 0.5) inset;
  font-size: 0.6em;
  line-height: 1;
  font-weight: 500;
  height: 100%;
  padding: 0 0.5rem;
  transition:
    background-color var(--transDur),
    color var(--transDur);
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.notification__btn-text {
  display: inline-block;
  pointer-events: none;
}

.notification__btn:first-of-type {
  border-radius: 0 0.75rem 0 0;
}

.notification__btn:last-of-type {
  border-radius: 0 0 0.75rem 0;
}

.notification__btn:only-child {
  border-radius: 0 0.75rem 0.75rem 0;
}

.notification__btn+.notification__btn {
  box-shadow: 0 -1px 0 hsla(var(--hue), 10%, 10%, 0.15);
  font-weight: 400;
}

.notification__btn:active,
.notification__btn:focus {
  background-color: hsl(var(--hue), 10%, 95%);
}

.notification__btn:focus {
  outline: transparent;
}


/* Animations */
@keyframes flyIn {
  from {
    transform: translateX(calc(100% + 1.5em));
  }

  to {
    transform: translateX(0);
  }
}

@keyframes flyOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(100% + 1.5em));
  }
}

/* home page styles */


.contenedor-imagen {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  top: 20px;
}

.contenedor-imagen::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(259.53deg, rgba(0, 0, 0, 0) 4.8%, rgba(0, 0, 0, 0.93) 91.26%);
  z-index: 1;
}


.imagen-con-borde {
  display: block;
  border-radius: 8px;
  width: 500px;
  height: auto;
}

.contenedor-texto {
  position: absolute;
  bottom: 35%;
  left: 0;
  font-size: 30px;
  z-index: 2;
  color: white;
  padding: 4px;
  font-weight: 600;
  left: 20px;
}

.description-card {
  position: absolute;
  bottom: 28%;
  left: 0;
  font-size: 14px;
  z-index: 2;
  color: white;
  padding: 4px;
  font-weight: 400;
  left: 20px;
}

.button-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10%;
  height: 37px;
  left: 20px;
  font-size: 14px;
  z-index: 2;
  color: white;
  padding: 10px 16px;
  font-weight: 400;
  border: 1px solid white;
  border-radius: 8px;
  cursor: pointer;
  width: auto;
  font-weight: 700;
  font-family: 'Fira Sans Condensed', sans-serif;
}




/* products page */

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  /* margin-right: 20px;  */
}


.blur-on-hover {
  position: relative;
  overflow: hidden;
}

.blur-on-hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.9s ease;
  z-index: 1;
}

.blur-on-hover:hover::after {
  opacity: 1;
}

.blur-on-hover:hover .add-to-cart-button {
  opacity: 1;
  transition: opacity 0.8s ease;
}

.blur-on-hover:hover img {
  filter: blur(8px);
}


.add-to-cart-button {
  background-color: #dc2626;
  color: white;
  border: none;
  padding: 10px 10px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  width: 50%;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.blur-on-hover:hover .add-to-cart-button {
  display: block;
}

.wheel-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90.17deg, rgba(0, 0, 0, 0.59) 0.15%, rgba(0, 0, 0, 0) 99.87%);
  z-index: 1;
}

.custom-badge .ant-ribbon {
  top: 0;
  left: -20px;
  transform: translateX(-70%);
}



.custom-ribbon .ant-ribbon-corner {
  display: none;
}

.custom-ribbon .ant-ribbon {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}



.custom-checkbox .ant-checkbox-inner {
  border-color: black;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}

.custom-checkbox:hover .ant-checkbox-inner,
.custom-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.custom-checkbox.ant-checkbox-checked:hover .ant-checkbox-inner {
  border-color: black;
}

.custom-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: black;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.custom-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: black;
}

.custom-checkbox.ant-checkbox-checked .ant-checkbox-inner::before {
  background-color: black;
}