body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.DateRangePicker_picker {
  z-index: 9999 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.react-daterange-picker__wrapper {
  @apply block w-full p-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.react-date-picker__wrapper {
  @apply block w-full p-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm;
}

.Toastify__toast {}

.react-daterange-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  align-items: center !important;
  border: thin solid gray !important;
}

.react-daterange-picker__inputGroup__input {
  min-width: 0.54em !important;
  height: 100% !important;
  position: relative !important;
  padding: 0 1px !important;
  border: 0 !important;
  background: none !important;
  font: inherit !important;
  box-sizing: content-box !important;
  -moz-appearance: textfield !important;
}

.react-date-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  align-items: center !important;
  border: thin solid gray !important;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em !important;
  height: 100% !important;
  position: relative !important;
  padding: 0 1px !important;
  border: 0 !important;
  background: none !important;
  font: inherit !important;
  box-sizing: content-box !important;
  -moz-appearance: textfield !important;
}